import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpshagunmalltower/hero/Hero';
import Landmark from '../../components/common/cpshagunmalltower/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Residential Towers In Goregaon (E), | Shagun Mall - Dynamix Group</title>
        <meta name="description" content="Shagun Mall & Tower by Dynamix Group is among real estate project in the vibrant suburb that offers exceptional amenities & connectivity in Goregaon East." />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
