import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import TopmainHeadingnri from '../../components/common/nri/topmainheadingnri/TopmainHeadingnri';
import NriFaq from '../../components/common/nri/nrifaq/NriFaq';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";


const Nri = () => {
  return (
    <>
      <Helmet>
        <title>NRI Guide | Homes For NRI's - Dynamix Group</title>
        <meta name="description" content="Dynamix Group is India's leading real estate developer, offering luxurious homes which are great investments for NRIs. Visit now!" />
      </Helmet>

      <Navigation />
      <TopmainHeadingnri />
      <NriFaq />
      <Contact />
      <Footer />
    </>
  );
};

export default Nri;
