import React, { useState } from 'react';
import { motion } from 'framer-motion';
//Components
import ProjectSlider from './ProjectSlider';
// Stylesheet
import './projects.scss';

// data
import { projectData2, projectData1 } from '../../../../constants/projectData';
import AnimateContainer from '../../../../animate/AnimateContainer';

export const textAnimate = {
  offscreen: { y: 20, opacity: 0 },
  onscreen: {
    y: 0,
    opacity: 1,
    // transition: { type: 'spring', delay: 0.2, duration: 2 }
    transition: { duration: 1, delay: 0.5, type: 'tween' }
  }
};

const placeholderText = [
  {
    type: 'heading2',
    text: 'COME AND SEE',
    class: ''
  },
  {
    type: 'heading2',
    text: 'WITH YOUR OWN EYES',
    class: 'detail'
  }
];

const Projects = () => {
  const [active, setActive] = useState(true);
  return (
    <section className="project">
      <div className="project_title_container">
        <motion.div className="project_title" initial="offscreen" whileInView="onscreen">
          <motion.p variants={textAnimate} className="title_underline">
            OUR PROJECTS
          </motion.p>
          <motion.h2 variants={textAnimate}>
          Discover Our Portfolio <br />of Inspired Creations 
          </motion.h2>
          {/* <AnimateContainer placeholderText={placeholderText} /> */}
          <p>Discover the true spirit of Dynamix Group's unwavering dedication to transforming real estate landscape</p>
        </motion.div>
      </div>
      <div className="project_tab">
        <div>
          <h3 onClick={() => setActive(true)} className={active ? 'menu_active' : ''}>
            Current
          </h3>
          <h3 onClick={() => setActive(!active)} className={!active ? 'menu_active' : ''}>
            Completed
          </h3>
        </div>
      </div>
      {active ? <ProjectSlider projectData={projectData1} /> : <ProjectSlider projectData={projectData2} />}
    </section>
  );
};

export default Projects;
