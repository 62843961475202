import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/raet_ft.webp";
// Stylesheet
import "./landmarkgoa.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkgoa' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>A NEW GOAN STANDARD</h2>
                    <img src={LandmarkImg} alt="landmark img" />
                </div>
                <div>
                    <h4>A paragon of Goan soul, combined with the
                        modernity of minimalism, Raet takes inspiration
                        from some of the world’s most popular beachfronts
                        without straying too far from home.
                    </h4>
                    <p>A collective of 4-bed residences and 7-bed
                        penthouses, every home enjoys an exclusive view of
                        the Bambolim Bay. Located at the top of a crescent
                        shaped hillside, the buildings look the horizon in
                        the eye with traditional Goan homes stacked along
                        the slopes below. The spacious interiors, the high
                        ceilings, the 3 metre deep private decks, and the
                        stunning panoramic views of Bambolim Bay are an
                        ode to the luxurious life you have earned for
                        yourself.</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
