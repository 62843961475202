import React from 'react'
import Navigation from '../../components/common/navigation/Navigation'
import CompletedProject from '../../components/common/plpcompleted/completedproject/CompletedProject'
import Projectone from '../../components/common/plpcompleted/projectone/Projectone'
import Footer from '../../components/common/footer/Footer'
import { Helmet } from "react-helmet";




const PlpPagescompleted = () => {
  return (
    <>
      <Helmet>
        <title>Completed Projects Archives - Dynamix Group</title>
        <meta name="description" content="Explore completed real estate projects in Mumbai, Thane, and Goa by the Dynamix Group to know its 5 decades legacy. Visit to know more!" />
      </Helmet>
      <Navigation />
      <CompletedProject />
      <Projectone />
      <Footer />
    </>
  )
}

export default PlpPagescompleted
