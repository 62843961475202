import React from "react";
// Components
import Layout from "../../../layout/Layout/Layout";


// Stylesheet
import "./exterior.scss";

const Exterior = () => {
    return (
        <Layout>
        <section className='exterior' id="View">
            <div className='title_container'>
                <p>EXTERIOR VIEW</p>
                <h2>GET 360&deg; VIEWS <br /> FROM PARKWOODS</h2>
               
            </div>
            <iframe key="virtual-tour-1" width="100%" height="500" src="https://www.dynamixgroup.co.in/embed/360-degree/Thane/"
        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>

        </section>
        </Layout>
    )
}

export default Exterior;
