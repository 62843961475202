import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpsahyadri/hero/Hero';
import Landmark from '../../components/common/cpsahyadri/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>2/3BHK Residential Apartments In Malad | Sahyadri - Dynamix Group</title>
        <meta name="description" content="Sahyadri, posh 2 & 3 BHK apartments in Malad East that are spacious & well-equipped and offers an unspoilt and refreshing view." />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
