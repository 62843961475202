
import React from 'react'
import Navigation from '../../components/common/navigation/Navigation'
import CurrentProject from '../../components/common/plp/currentproject/CurrentProject'
import Projectone from '../../components/common/plp/projectone/Projectone'
import Footer from '../../components/common/footer/Footer'
import { Helmet } from "react-helmet";




const PlpPages = () => {
  return (
    <>
      <Helmet>
        <title>Residential & Commercial Properties In Mumbai, Thane and Goa</title>
        <meta name="description" content="The Dynamix Group is renowned for its well-designed residential and commercial properties in Mumbai, Thane and Goa. Call us to know more!" />
      </Helmet>
      <Navigation />
      <CurrentProject />
      <Projectone />
      <Footer />
    </>
  )
}

export default PlpPages
