import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpudyogbhawan/hero/Hero';
import Landmark from '../../components/common/cpudyogbhawan/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";


const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Commercial Projects In Goregaon (W) | Udyog Bhavan - Dynamix Group</title>
        <meta name="description" content="Udyog Bhavan is a commercial project, and this industrial estate complete with a basement & three floors, represents great investment for any booming business in Mumbai." />
      </Helmet>

      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
