//GOAICONFT.
import WalkinWardrobes from "../assets/icons/Aldeiadegoa/WalkinWardrobes.svg";
import ACApartment from "../assets/icons/Aldeiadegoa/ACApartment.svg";
import carparking from "../assets/icons/Aldeiadegoa/carparking.svg";
import ClubHouse from "../assets/icons/Aldeiadegoa/ClubHouse.svg";
import Bar from "../assets/icons/Aldeiadegoa/Bar.svg";
import FittedKitchen from "../assets/icons/Aldeiadegoa/FittedKitchen.svg";
import FurnishedApartments from "../assets/icons/Aldeiadegoa/FurnishedApartments.svg";
import GatedCommunity from "../assets/icons/Aldeiadegoa/GatedCommunity.svg";
import JoggingTrack from "../assets/icons/Aldeiadegoa/JoggingTrack.svg";
import MeditationGardens from "../assets/icons/Aldeiadegoa/MeditationGardens.svg";
import MultipleGymnasiums from "../assets/icons/Aldeiadegoa/MultipleGymnasiums.svg";
import PlayArea from "../assets/icons/Aldeiadegoa/PlayArea.svg";
import SanitaryFittings from "../assets/icons/Aldeiadegoa/SanitaryFittings.svg";
import SwimmingPools from "../assets/icons/Aldeiadegoa/SwimmingPools.svg";
import TileFlooring from "../assets/icons/Aldeiadegoa/TileFlooring.svg";
//astrum icon ft.
import BasementParking from "../assets/icons/Astrum/BasementParking.svg";
import Concierge from "../assets/icons/Astrum/Concierge.svg";
import BMSRoom from "../assets/icons/Astrum/BMSRoom.svg";
import CarLift from "../assets/icons/Astrum/CarLift.svg";
import CCTVMonitoring from "../assets/icons/Astrum/CCTVMonitoring.svg";
import ConditionedApartments from "../assets/icons/Astrum/ConditionedApartments.svg";
import DGPowerBackup from "../assets/icons/Astrum/DGPowerBackup.svg";
import ElectricPoints from "../assets/icons/Astrum/ElectricPoints.svg";
import GamesRoom from "../assets/icons/Astrum/GamesRoom.svg";
import GrandEntranceLobby from "../assets/icons/Astrum/GrandEntranceLobby.svg";
import Gymnasium from "../assets/icons/Astrum/Gymnasium.svg";
import HotTub from "../assets/icons/Astrum/HotTub.svg";
import library from "../assets/icons/Astrum/library.svg";
import MiniTheatre from "../assets/icons/Astrum/MiniTheatre.svg";
import MultipurposeHall from "../assets/icons/Astrum/MultipurposeHall.svg";
import SanitaryFittings1 from "../assets/icons/Astrum/SanitaryFittings1.svg";
import Sauna from "../assets/icons/Astrum/Sauna.svg";
import SewageTreatment from "../assets/icons/Astrum/SewageTreatment.svg";
import SocietyOffice from "../assets/icons/Astrum/SocietyOffice.svg";
import Spa from "../assets/icons/Astrum/Spa.svg";
import SpeedElevators from "../assets/icons/Astrum/SpeedElevators.svg";
import SwimmingPool from "../assets/icons/Astrum/SwimmingPool.svg";
import WaterHarvesting from "../assets/icons/Astrum/WaterHarvesting.svg";
import WindFactoredDesign from "../assets/icons/Astrum/WindFactoredDesign.svg";
//avanya icon ft.
import AirConditioned from "../assets/icons/Avanya/AirConditioned.svg";
import CCTVMonitoring1 from "../assets/icons/Avanya/CCTVMonitoring1.svg";
import ChildrenPlayArea from "../assets/icons/Avanya/ChildrenPlayArea.svg";
import CricketPitchZone from "../assets/icons/Avanya/CricketPitchZone.svg";
import DGPowerBackup1 from "../assets/icons/Avanya/DGPowerBackup1.svg";
import FittedKitchen1 from "../assets/icons/Avanya/FittedKitchen1.svg";
import GamesRoom1 from "../assets/icons/Avanya/GamesRoom1.svg";
import GrandEntranceLobby1 from "../assets/icons/Avanya/GrandEntranceLobby1.svg";
import Gymnasium1 from "../assets/icons/Avanya/Gymnasium1.svg";
import IGBCGoldcertified from "../assets/icons/Avanya/IGBCGoldcertified.svg";
import InternationalStandard from "../assets/icons/Avanya/InternationalStandard.svg";
import JoggingTrack1 from "../assets/icons/Avanya/JoggingTrack1.svg";
import MiniTheatre1 from "../assets/icons/Avanya/MiniTheatre1.svg";
import MultiPurposeCourt from "../assets/icons/Avanya/MultiPurposeCourt.svg";
import PetParkArea from "../assets/icons/Avanya/PetParkArea.svg";
import RainWaterHarvesting from "../assets/icons/Avanya/RainWaterHarvesting.svg";
import SeismicWindFactored from "../assets/icons/Avanya/SeismicWindFactored.svg";
import SewageTreatmentPlant from "../assets/icons/Avanya/SewageTreatmentPlant.svg";
import SolarWaterHeaters from "../assets/icons/Avanya/SolarWaterHeaters.svg";
import SpeedElevators1 from "../assets/icons/Avanya/SpeedElevators1.svg";
import SteamSauna from "../assets/icons/Avanya/SteamSauna.svg";
import Creche from "../assets/icons/Avanya/Creche.svg";
import YogaMeditationPavilion from "../assets/icons/Avanya/YogaMeditationPavilion.svg";
//divum icon ft.
import ACapartment from "../assets/icons/Divum/ACapartment.svg";
import Amplecarparking from "../assets/icons/Divum/Amplecarparking.svg";
import DesignatedFiremanLift from "../assets/icons/Divum/DesignatedFiremanLift.svg";
import FireDetectionSystems from "../assets/icons/Divum/FireDetectionSystems.svg";
import FitnessCenter from "../assets/icons/Divum/FitnessCenter.svg";
import Fittedkitchen from "../assets/icons/Divum/Fittedkitchen.svg";
import highspeedlift from "../assets/icons/Divum/highspeedlift.svg";
import DGset from "../assets/icons/Divum/DGset.svg";
import IGBC from "../assets/icons/Divum/IGBC.svg";
import indoorgames from "../assets/icons/Divum/indoorgames.svg";
import InfinitySwimmingPool from "../assets/icons/Divum/InfinitySwimmingPool.svg";
import JoggingTrack2 from "../assets/icons/Divum/JoggingTrack2.svg";
import kidspool from "../assets/icons/Divum/kidspool.svg";
import MeditationPavilion from "../assets/icons/Divum/MeditationPavilion.svg";
import minitheater from "../assets/icons/Divum/minitheater.svg";
import MultipurposeHall1 from "../assets/icons/Divum/MultipurposeHall1.svg";
import playarea from "../assets/icons/Divum/playarea.svg";
import quietlib from "../assets/icons/Divum/quietlib.svg";
import security from "../assets/icons/Divum/security.svg";
import seprategarbagecollection from "../assets/icons/Divum/seprategarbagecollection.svg";
import Sprinklers from "../assets/icons/Divum/Sprinklers.svg";
import videodoor from "../assets/icons/Divum/videodoor.svg";
import yogastudio from "../assets/icons/Divum/yogastudio.svg";
//luma icon ft.
import AirApartments from "../assets/icons/Luma/AirApartments.svg";
import AlternatePowers from "../assets/icons/Luma/AlternatePowers.svg";
import ChildrenPlayArea1 from "../assets/icons/Luma/ChildrenPlayArea1.svg";
import ElectricCarCharging from "../assets/icons/Luma/ElectricCarCharging.svg";
import firesafty from "../assets/icons/Luma/firesafty.svg";
import fitures from "../assets/icons/Luma/fitures.svg";
import GrandEntrance from "../assets/icons/Luma/GrandEntrance.svg";
// import Gymnasium2 from "../assets/icons/Luma/Gymnasium2.svg";
import IGBC1 from "../assets/icons/Luma/IGBC1.svg";
import indoorgames1 from "../assets/icons/Luma/indoorgames1.svg";
import joggingTrack from "../assets/icons/Luma/joggingTrack.svg";
import lib from "../assets/icons/Luma/lib.svg";
import minitheater1 from "../assets/icons/Luma/minitheater1.svg";
import MultipurposeHall2 from "../assets/icons/Luma/MultipurposeHall2.svg";
import seprateelevater from "../assets/icons/Luma/seprateelevater.svg";
import SewageTreatment1 from "../assets/icons/Luma/SewageTreatment1.svg";
import SolarPanels from "../assets/icons/Luma/SolarPanels.svg";
import WaterHarvesting1 from "../assets/icons/Luma/WaterHarvesting1.svg";



//parkwood icon ft.
import AirConditionedApartments from "../assets/icons/Parkwoods/AirConditionedApartments.svg";
import BanquetingSpaces from "../assets/icons/Parkwoods/BanquetingSpaces.svg";
import BusinessLounge from "../assets/icons/Parkwoods/BusinessLounge.svg";
import ClubHouse1 from "../assets/icons/Parkwoods/ClubHouse1.svg";
import CricketNet from "../assets/icons/Parkwoods/CricketNet.svg";
import landscapearea from "../assets/icons/Parkwoods/landscapearea.svg";
import FittedKitchen2 from "../assets/icons/Parkwoods/FittedKitchen2.svg";
import FullyKittedOutGym from "../assets/icons/Parkwoods/FullyKittedOutGym.svg";
import HighSpeedElevators from "../assets/icons/Parkwoods/HighSpeedElevators.svg";
import HighSpeedEntranceLobbies from "../assets/icons/Parkwoods/HighSpeedEntranceLobbies.svg";
import IGBCGoldPrecertified from "../assets/icons/Parkwoods/IGBCGoldPrecertified.svg";
import KidsPlayArea from "../assets/icons/Parkwoods/KidsPlayArea.svg";
import LevelsofSecurity from "../assets/icons/Parkwoods/LevelsofSecurity.svg";
import Library from "../assets/icons/Parkwoods/Library.svg";
import MultipleIndoorGamesRoom from "../assets/icons/Parkwoods/MultipleIndoorGamesRoom.svg";
import MultipleSwimmingPools from "../assets/icons/Parkwoods/MultipleSwimmingPools.svg";
import MultiPurposeCourt1 from "../assets/icons/Parkwoods/MultiPurposeCourt1.svg";
import PartyLawn from "../assets/icons/Parkwoods/PartyLawn.svg";
import RainWaterHarvesting1 from "../assets/icons/Parkwoods/RainWaterHarvesting1.svg";
import RockClimbingWall from "../assets/icons/Parkwoods/RockClimbingWall.svg";
import SeismicandWind from "../assets/icons/Parkwoods/SeismicandWind.svg";
import sevagetreat from "../assets/icons/Parkwoods/sevagetreat.svg";
import ShuttleElevatorsforParking from "../assets/icons/Parkwoods/ShuttleElevatorsforParking.svg";
import SoftJoggingTrack from "../assets/icons/Parkwoods/SoftJoggingTrack.svg";
import SolarWaterHeaters1 from "../assets/icons/Parkwoods/SolarWaterHeaters1.svg";
import SprinklersinEveryFloor from "../assets/icons/Parkwoods/SprinklersinEveryFloor.svg";
import yogamedi from "../assets/icons/Parkwoods/yogamedi.svg";
import mailbox from "../assets/icons/Parkwoods/mailbox.svg";

//srishtinamaah icon ft.
import AirConditionedApartments1 from "../assets/icons/SrishtiNamaah/AirConditionedApartments1.svg";
import CCTVSurveillance from "../assets/icons/SrishtiNamaah/CCTVSurveillance.svg";
import Creche1 from "../assets/icons/SrishtiNamaah/Creche1.svg";
import DGPowerBackup2 from "../assets/icons/SrishtiNamaah/DGPowerBackup2.svg";
import DoubleHeightLobbies from "../assets/icons/SrishtiNamaah/DoubleHeightLobbies.svg";
import GamesRoom2 from "../assets/icons/SrishtiNamaah/GamesRoom2.svg";
import Gymnasium3 from "../assets/icons/SrishtiNamaah/Gymnasium3.svg";
import HighSpeedElevators1 from "../assets/icons/SrishtiNamaah/HighSpeedElevators1.svg";
import JoggingWalkingTrack from "../assets/icons/SrishtiNamaah/JoggingWalkingTrack.svg";
import LandscapedGarden from "../assets/icons/SrishtiNamaah/LandscapedGarden.svg";
import MultiPurposeCourt2 from "../assets/icons/SrishtiNamaah/MultiPurposeCourt2.svg";
import MultitierSecurity from "../assets/icons/SrishtiNamaah/MultitierSecurity.svg";
import OutdoorYoga from "../assets/icons/SrishtiNamaah/OutdoorYoga.svg";
import PipedGasConnection from "../assets/icons/SrishtiNamaah/PipedGasConnection.svg";
import PremiumSanitaryCPFittings from "../assets/icons/SrishtiNamaah/PremiumSanitaryCPFittings.svg";
import RainWaterHarvesting2 from "../assets/icons/SrishtiNamaah/RainWaterHarvesting2.svg";
import SewageTreatmentPlant1 from "../assets/icons/SrishtiNamaah/SewageTreatmentPlant1.svg";
import SwimmingPool1 from "../assets/icons/SrishtiNamaah/SwimmingPool1.svg";
import VideoDoorPhone from "../assets/icons/SrishtiNamaah/VideoDoorPhone.svg";

//raet icon ft.
import skydeck from "../assets/icons/raet/skydeck.svg";
import plumbercourt from "../assets/icons/raet/plumbercourt.svg";
import amphitheatre from "../assets/icons/raet/amphitheatre.svg";
import lanndscapedpads from "../assets/icons/raet/lanndscapedpads.svg";



export const keyfeaturesdata = [

	// {
	// 	title: "2 Security Checkpoints",
	// 	src: security

	// },
	{
		title: "Air Conditioned Apartments",
		src: ACapartment

	},
	{
		title: "Ample Car Parking Space",
		src: Amplecarparking

	},
	{
		title: "Ample High-speed Lifts",
		src: highspeedlift

	},
	{
		title: "DG Set Backup",
		src: DGset

	},
	// {
	// 	title: "Designated Fireman’s Lift",
	// 	src: DesignatedFiremanLift

	// },
	{
		title: "Exciting Play Area",
		src: playarea

	},
	{
		title: "Fitness Center",
		src: FitnessCenter

	},
	{
		title: "Fitted Kitchen",
		src: Fittedkitchen

	},
	{
		title: "IGBC Gold Certified",
		src: IGBC

	},
	{
		title: "Indoor Games Room",
		src: indoorgames

	},
	{
		title: "Infinity Edge Swimming Pool",
		src: InfinitySwimmingPool

	},
	{
		title: "Jogging Track",
		src: JoggingTrack2

	},
	// {
	// 	title: "Kids' Pool",
	// 	src: kidspool

	// },
	{
		title: "Meditation Pavilion",
		src: MeditationPavilion

	},
	{
		title: "Multipurpose Hall",
		src: MultipurposeHall1

	},
	{
		title: "Private Mini Theatre",
		src: minitheater

	},
	{
		title: "Quiet Library",
		src: quietlib

	},
	// {
	// 	title: "Separate Garbage Collection & Processing Points",
	// 	src: seprategarbagecollection

	// },
	// {
	// 	title: "Sprinklers In Every Room",
	// 	src: Sprinklers

	// },
	{
		title: "State of the Art Fire Detection Systems",
		src: FireDetectionSystems

	},
	{
		title: "Video-door Phones In All Apartments",
		src: videodoor

	},
	{
		title: "Yoga Studio",
		src: yogastudio

	},
	
]


export const Lumakeyfeaturesdata = [

	{
		title: "Air Conditioned Apartments",
		src: AirApartments

	},
	{
		title: "Children's Play Area",
		src: ChildrenPlayArea1

	},
	{
		title: "Jogging Track",
		src: joggingTrack

	},
	{
		title: "Gymnasium",
		src: Gymnasium1

	},
	{
		title: "Multipurpose Hall",
		src: MultipurposeHall2

	},
	{
		title: "Library",
		src: lib

	},
	{
		title: "Indoor Games Room",
		src: indoorgames1

	},
	{
		title: "Mini Theatre",
		src: minitheater1

	},
	{
		title: "Rain Water Harvesting",
		src: WaterHarvesting1

	},
	{
		title: "Grand Entrance Lobby",
		src: GrandEntrance

	},
	{
		title: "Sewage Treatment Plant",
		src: SewageTreatment1

	},
	{
		title: "Electric Car Charging Facility",
		src: ElectricCarCharging

	},
	{
		title: "IGBC Certification",
		src: IGBC1

	},
	{
		title: "Alternate Power Supply Source",
		src: AlternatePowers

	},
	{
		title: "Solar Panels",
		src: SolarPanels

	},
	{
		title: "International Standard Fittings & Fixtures",
		src: fitures

	},
	{
		title: "Separate Service Elevators",
		src: seprateelevater

	},
	{
		title: "Fire Safety Compliance",
		src: firesafty

	},

	
]


export const Avanyakeyfeaturesdata = [

	{
		title: "Air Conditioned Apartments",
		src: AirConditioned

	},
	{
		title: "CCTV Monitoring",
		src: CCTVMonitoring1

	},
	{
		title: "Children's Play Area",
		src: ChildrenPlayArea

	},
	{
		title: "Cricket Pitch Zone",
		src: CricketPitchZone

	},
	{
		title: "DG Power Backup",
		src: DGPowerBackup1

	},
	{
		title: "Fitted Kitchen",
		src: FittedKitchen1

	},
	{
		title: "Games Room",
		src: GamesRoom1

	},
	{
		title: "Grand Entrance Lobby",
		src: GrandEntranceLobby1

	},
	{
		title: "Gymnasium",
		src: Gymnasium1

	},
	{
		title: "High Speed Elevators",
		src: SpeedElevators1

	},
	{/////////
		title: "IGBC Gold Pre-certified",
		src: IGBCGoldcertified

	},
	{
		title: "International Standard Cp & Sanitary Fixtures",
		src: InternationalStandard

	},
	{
		title: "Jogging Track",
		src: JoggingTrack1

	},
	{
		title: "Kids' Activity Area & Creche",
		src: Creche

	},
	{
		title: "Mini Theatre",
		src: MiniTheatre1

	},
	{
		title: "Multi Purpose Court",
		src: MultiPurposeCourt

	},
	{
		title: "Pet Park Area",
		src: PetParkArea

	},
	{
		title: "Rain Water Harvesting",
		src: RainWaterHarvesting

	},
	{
		title: "Seismic & Wind Factored Design",
		src: SeismicWindFactored

	},
	{
		title: "Sewage Treatment Plant",
		src: SewageTreatmentPlant

	},
	{
		title: "Solar Water Heaters",
		src: SolarWaterHeaters

	},
	{
		title: "Steam & Sauna",
		src: SteamSauna

	},
	{
		title: "Swimming Pool",
		src: SwimmingPool

	},
	{
		title: "Yoga & Meditation Pavilion",
		src: YogaMeditationPavilion

	},

	
]



export const Parkwoodkeyfeaturesdata = [

	// {
	// 	title: "3 Levels of Security",
	// 	src: LevelsofSecurity

	// },
	{
		title: "6 High-Speed Elevators with Designated Fire Lifts",
		src: HighSpeedElevators

	},
	{
		title: "Air Conditioned Apartments",
		src: AirConditionedApartments

	},
	{
		title: "Banqueting Spaces",
		src: BanquetingSpaces

	},
	{
		title: "Business Lounge",
		src: BusinessLounge

	},
	{
		title: "Club House",
		src: ClubHouse1

	},
	{
		title: "Cricket Net",
		src: CricketNet

	},
	{
		title: "Double Height Entrance Lobby",
		src: HighSpeedEntranceLobbies

	},
	{
		title: "Fitted Kitchen",
		src: FittedKitchen2

	},
	{
		title: "Fully Kitted Out Gym",
		src: FullyKittedOutGym

	},
	{
		title: "IGBC Gold Pre-certified",
		src: IGBCGoldPrecertified

	},
	{
		title: "Kids' Play Area",
		src: KidsPlayArea

	},
	{
		title: "Library",
		src: Library

	},
	// {
	// 	title: "Mail Box Room and Society Offices",
	// 	src: mailbox

	// },
	{
		title: "Multi Purpose Court",
		src: MultiPurposeCourt1

	},
	{
		title: "Multiple Indoor Games Room",
		src: MultipleIndoorGamesRoom

	},
	{
		title: "Multiple Swimming Pools",
		src: MultipleSwimmingPools

	},
	{
		title: "Party Lawn",
		src: PartyLawn

	},
	{
		title: "Rain Water Harvesting",
		src: RainWaterHarvesting1

	},
	{
		title: "Rock Climbing Wall",
		src: RockClimbingWall

	},
	// {
	// 	title: "Seismic and Wind Factored Design",
	// 	src: SeismicandWind

	// },
	// {
	// 	title: "Shuttle Elevators for Parking",
	// 	src: ShuttleElevatorsforParking

	// },
	{
		title: "Soft Jogging Track",
		src: SoftJoggingTrack

	},
	{
		title: "Solar Water Heaters",
		src: SolarWaterHeaters1

	},
	{
		title: "Sprinklers in Every Floor & Room",
		src: SprinklersinEveryFloor

	},
	{
		title: "Waste Water Recycling Plant",
		src: sevagetreat

	},
	{
		title: "Landscaped Garden",
		src: landscapearea

	},
	{
		title: "Yoga and Wellness Center",
		src: yogamedi

	},
	
]


export const astrumfeaturesdata = [

	{
		title: "3-Level Basement Parking",
		src: BasementParking

	},
	{
		title: "Air Conditioned Apartments",
		src: ConditionedApartments

	},
	{
		title: "BMS Room",
		src: BMSRoom

	},
	{
		title: "CCTV Monitoring",
		src: CCTVMonitoring

	},
	{
		title: "Car Lift",
		src: CarLift

	},
	{
		title: "DG Power Backup",
		src: DGPowerBackup

	},
	{
		title: "Concierge",
		src: Concierge

	},
	{
		title: "Future-Ready Electric Points for e-vehicles",
		src: ElectricPoints

	},
	{
		title: "Games Room",
		src: GamesRoom

	},
	{
		title: "Grand Entrance Lobby",
		src: GrandEntranceLobby

	},
	{
		title: "Gymnasium",
		src: Gymnasium

	},
	{
		title: "High Speed Elevators",
		src: SpeedElevators

	},
	{
		title: "Hot Tub",
		src: HotTub

	},
	{
		title: "Library",
		src: library

	},
	{
		title: "Mini Theatre",
		src: MiniTheatre

	},
	{
		title: "Multipurpose Hall",
		src: MultipurposeHall

	},
	{
		title: "Premium Fixtures and Sanitary Fittings",
		src: SanitaryFittings1

	},
	{
		title: "Rain Water Harvesting",
		src: WaterHarvesting

	},
	{
		title: "Sauna",
		src: Sauna

	},
	{
		title: "Seismic and Wind Factored Design",
		src: WindFactoredDesign

	},
	{
		title: "Sewage Treatment Plant",
		src: SewageTreatment

	},
	{
		title: "Society Office",
		src: SocietyOffice

	},
	{
		title: "Spa",
		src: Spa

	},
	{
		title: "Swimming Pool",
		src: SwimmingPool

	},

	
]



export const degoafeaturesdata = [

	// {
	// 	title: "Aesthetically Finished Fitted Walk in Wardrobes",
	// 	src: WalkinWardrobes

	// },
	{
		title: "Ample Parking",
		src: carparking
		
	},
	{
		title: "Children's Play Area",
		src: PlayArea
		
	},
	{
		title: "Grand Club House",
		src: ClubHouse
		
	},
	{
		title: "Gated Community with 24x7 Security Personnel",
		src: GatedCommunity
		
	},
	{
		title: "Bar",
		src: Bar

	},
	// {
		// 	title: "High Quality Designer Vetrified Tile Flooring",
		// 	src: TileFlooring
		
		// },
		{
			title: "International Standard Fitted Kitchen",
			src: FittedKitchen
			
		},
		{
			title: "Banquet Spaces",
		src: BanquetingSpaces

	},
	{
		title: "Long Walkways and Jogging Track",
		src: JoggingTrack

	},
	{
		title: "Meditation Gardens and Landscaped Green Spaces",
		src: MeditationGardens

	},
	{
		title: "Multiple Swimming Pools",
		src: SwimmingPools

	},
	{
		title: "Multiple Gymnasiums",
		src: MultipleGymnasiums

	},
	{
		title: "Premium CP and Sanitary Fittings",
		src: SanitaryFittings

	},
	// {
	// 	title: "Semi Furnished Apartments",
	// 	src: FurnishedApartments

	// },
	
]

export const raetfeaturesdata = [

	{
		title: "FITNESS CENTRE(GYM)",
		src: FitnessCenter
		
	},
	{
		title: "SWIMMING POOL",
		src: SwimmingPool
		
	},
	{
		title: "CHILDREN’S PLAY AREA",
		src: ChildrenPlayArea1
		
	},
	{
		title: "SKY DECK",
		src: skydeck
		
	},
	{
		title: "YOGA STUDIO",
		src: OutdoorYoga

	},
	{
		title: "INDOOR GAMES AREA",
		src: indoorgames

	},
	{
		title: "PLUMERIA COURT",
		src: plumbercourt

	},
		{
			title: "LANDSCAPED PADS",
			src: lanndscapedpads
			
		},
		{
			title: "MULTIPURPOSE HALL",
		src: MultipurposeHall

	},
	{
		title: "AMPHITHEATRE",
		src: amphitheatre

	},

	
	
]

export const amenitiesfeaturesdata = [

	{
		title: "FITNESS CENTRE(GYM)",
		src: carparking
		
	},
	{
		title: "SWIMMING POOL",
		src: SwimmingPool
		
	},
	{
		title: "CHILDREN’S PLAY AREA",
		src: ChildrenPlayArea1
		
	},
	{
		title: "RAIN FOREST FEATURE WALL",
		src: RainWaterHarvesting
		
	},
	{
		title: "YOGA AND WELLNESS CENTER",
		src: OutdoorYoga

	},
	{
		title: "INDOOR GAMES AREA",
		src: MultipleIndoorGamesRoom

	},
	{
		title: "JOGGING TRACK",
		src: JoggingTrack

	},
	{
		title: "DESIGNED LANDSCAPE AREA",
		src: landscapearea

	},
		{
			title: "PARTY LAWN",
			src: PartyLawn
			
		},
		{
			title: "LIBRARY",
		src: library

	},
	{
		title: "ELECTRIC CAR CHARGING FACILITY*",
		src: ElectricPoints

	},
	{
		title: "ENTRANCE LOBBY",
		src: GrandEntrance

	},
	{
		title: "PREMIUM CP AND SANITARY FIXTURES",
		src: PremiumSanitaryCPFittings

	},
	{
		title: "ALTERNATE POWER SUPPLY SOURCE",
		src: AlternatePowers

	},
	{
		title: "SEWAGE TREATMENT PLANT",
		src: SewageTreatmentPlant1

	},
	{
		title: "FIRE SAFETY COMPLIANT",
		src: firesafty

	},
	{
		title: "RAIN WATER HARVESTING TANK",
		src: RainWaterHarvesting2

	},

	
	
]



export const srishtifeaturesdata = [

	{
		title: "Air Conditioned Lobbies",
		src: AirConditionedApartments1

	},
	{
		title: "CCTV Surveillance",
		src: CCTVSurveillance

	},
	{
		title: "Creche",
		src: Creche1

	},
	{
		title: "DG Power Backup",
		src: DGPowerBackup2

	},
	{
		title: "Double Height Lobbies",
		src: DoubleHeightLobbies

	},
	{
		title: "Games Room",
		src: GamesRoom2

	},
	{
		title: "Gymnasium",
		src: Gymnasium3

	},
	{
		title: "High Speed Elevators",
		src: HighSpeedElevators1

	},
	{
		title: "Intercom facility integrated with Video Door Phone",
		src: VideoDoorPhone

	},
	{
		title: "Jogging/Walking Track",
		src: JoggingWalkingTrack

	},
	{
		title: "Multi-tier Security & Firefighting",
		src: MultitierSecurity

	},
	{
		title: "Multi Purpose Court",
		src: MultiPurposeCourt2

	},
	{
		title: "Outdoor Yoga & Exercise Areas",
		src: OutdoorYoga

	},
	// {
	// 	title: "Piped Gas Connection",
	// 	src: PipedGasConnection

	// },
	{
		title: "Premium Sanitary & CP Fittings",
		src: PremiumSanitaryCPFittings

	},
	{
		title: "Rain Water Harvesting",
		src: RainWaterHarvesting2

	},
	{
		title: "Sewage Treatment Plant",
		src: SewageTreatmentPlant1

	},
	{
		title: "Swimming Pool",
		src: SwimmingPool

	},
	{
		title: "Landscaped Garden",
		src: LandscapedGarden

	},

	
	
]