import Lamen1 from "../assets/experience/Lamen1.webp";
import Lamen2 from "../assets/experience/Lamen2.webp";
import Lamen3 from "../assets/experience/Lamen3.webp";
import Lamen4 from "../assets/experience/Lamen4.webp";
import Lamen5 from "../assets/experience/Lamen5.webp";
import Lamen6 from "../assets/experience/Lamen6.webp";
import Lamen7 from "../assets/experience/Lamen7.webp";
import Lamen8 from "../assets/experience/Lamen8.webp";
import Lamen9 from "../assets/experience/Lamen9.webp";

import DAmen1rep from "../assets/experience/DAmen1rep.webp";
import DAmen2rep from "../assets/experience/DAmen2rep.webp"; 
import DAmen3rep from "../assets/experience/DAmen3rep.webp";
import DAmen4rep from "../assets/experience/DAmen4rep.webp";
import DAmen5rep from "../assets/experience/DAmen5rep.webp";
import DAmen6rep from "../assets/experience/DAmen6rep.webp";
import DAmen7rep from "../assets/experience/DAmen7rep.webp";
import DAmen8rep from "../assets/experience/DAmen8rep.webp";
import DAmen9rep from "../assets/experience/DAmen9rep.webp";
import DAmen10rep from "../assets/experience/DAmen10rep.webp";
import DAmen11rep from "../assets/experience/DAmen11rep.webp";
import DAmen12rep from "../assets/experience/DAmen12rep.webp";
import DAmen13rep from "../assets/experience/DAmen13rep.webp";
import DAmen14rep from "../assets/experience/DAmen14rep.webp";

import AAmen1rep from "../assets/experience/AAmen1rep.webp";
import AAmen2rep from "../assets/experience/AAmen2rep.webp";
import AAmen3rep from "../assets/experience/AAmen3rep.webp";
import AAmen4rep from "../assets/experience/AAmen4rep.webp";
import AAmen5rep from "../assets/experience/AAmen5rep.webp";
import AAmen6rep from "../assets/experience/AAmen6rep.webp";
import AAmen7rep from "../assets/experience/AAmen7rep.webp";
import AAmen8rep from "../assets/experience/AAmen8rep.webp";
import AAmen9rep from "../assets/experience/AAmen9rep.webp";
import AAmen10rep from "../assets/experience/AAmen10rep.webp";
import AAmen11rep from "../assets/experience/AAmen11rep.webp";
import AAmen12rep from "../assets/experience/AAmen12rep.webp";
import AAmen13rep from "../assets/experience/AAmen13rep.webp";
import AAmen14rep from "../assets/experience/AAmen14rep.webp";
import AAmen15rep from "../assets/experience/AAmen15rep.webp";
import AAmen16rep from "../assets/experience/AAmen16rep.webp";
import AAmen17rep from "../assets/experience/AAmen17rep.webp";
import AAmen18rep from "../assets/experience/AAmen18rep.webp";


import Pamen1rep from "../assets/experience/Pamen1rep.webp";
import Pamen2rep from "../assets/experience/Pamen2rep.webp";
import Pamen3rep from "../assets/experience/Pamen3rep.webp";
import Pamen4rep from "../assets/experience/Pamen4rep.webp";
import Pamen5rep from "../assets/experience/Pamen5rep.webp";
import Pamen6rep from "../assets/experience/Pamen6rep.webp";
import Pamen7rep from "../assets/experience/Pamen7rep.webp";
import Pamen8rep from "../assets/experience/Pamen8rep.webp";
import Pamen9rep from "../assets/experience/Pamen9rep.webp";
import Pamen10rep from "../assets/experience/Pamen10rep.webp";
import Pamen11rep from "../assets/experience/Pamen11rep.webp";
import Pamen12rep from "../assets/experience/Pamen12rep.webp";
import Pamen13rep from "../assets/experience/Pamen13rep.webp";




import Asamen1rep from "../assets/experience/Asamen1rep.webp";
import Asamen2rep from "../assets/experience/Asamen2rep.webp";
import Asamen3rep from "../assets/experience/Asamen3rep.webp";
import Asamen4rep from "../assets/experience/Asamen4rep.webp";
import Asamen5rep from "../assets/experience/Asamen5rep.webp";
import Asamen6rep from "../assets/experience/Asamen6rep.webp";
import Asamen7rep from "../assets/experience/Asamen7rep.webp";
import Asamen8rep from "../assets/experience/Asamen8rep.webp";
import Asamen9rep from "../assets/experience/Asamen9rep.webp";



import Gamen1rep from "../assets/experience/Gamen1rep.webp";
import Gamen2rep from "../assets/experience/Gamen2rep.webp";
import Gamen3rep from "../assets/experience/Gamen3rep.webp";
import Gamen5rep from "../assets/experience/Gamen5rep.webp";
import Gamen6rep from "../assets/experience/Gamen6rep.webp";
import Gamen7rep from "../assets/experience/Gamen7rep.webp";
import Gamen8rep from "../assets/experience/Gamen8rep.webp";
import Gamen9rep from "../assets/experience/Gamen9rep.webp";

import raet1rep from "../assets/experience/Emp.-Area.webp";
import raet2rep from "../assets/experience/Gym.webp";
import raet3rep from "../assets/experience/Pool.webp";
import kidsplayarea from "../assets/experience/kidsplayarea.jpg";

import amenities1rep from "../assets/experience/amenities/Gym.webp";
import amenities2rep from "../assets/experience/amenities/Landscape-Garden.webp";
import amenities3rep from "../assets/experience/amenities/Landscape-Garden_1.webp";
import amenities4rep from "../assets/experience/amenities/Swimming-Pool.webp";
import amenities5rep from "../assets/experience/amenities/WaterFall.webp";


import Samen1rep from "../assets/experience/Samen1rep.webp"
import Samen2rep from "../assets/experience/Samen2rep.webp"
import Samen3rep from "../assets/experience/Samen3rep.webp"
import Samen4rep from "../assets/experience/Samen4rep.webp"
import Samen5rep from "../assets/experience/Samen5rep.webp"
import Samen6rep from "../assets/experience/Samen6rep.webp"

import edus1 from "../assets/eduslide/1.jpg"
import edus2 from "../assets/eduslide/2.jpg"
import edus3 from "../assets/eduslide/3.jpg"
import edus4 from "../assets/eduslide/4.jpg"
import edus5 from "../assets/eduslide/5.jpg"
import edus6 from "../assets/eduslide/6.jpg"
import edus7 from "../assets/eduslide/7.jpg"
import edus8 from "../assets/eduslide/8.jpg"
import edus9 from "../assets/eduslide/9.jpg"
import edus10 from "../assets/eduslide/10.jpg"
import edus11 from "../assets/eduslide/11.jpg"
import edus12 from "../assets/eduslide/12.jpg"


import dairy1 from "../assets/dairyslide/d1.jpg"
import dairy2 from "../assets/dairyslide/d2.jpg"
import dairy3 from "../assets/dairyslide/d3.jpg"
import dairy4 from "../assets/dairyslide/d4.jpg"

import healths1 from "../assets/healthslide/h1.jpg"
import healths2 from "../assets/healthslide/h2.jpg"
import healths3 from "../assets/healthslide/h3.jpg"









export const expData = [
	{
		src: Lamen1,
		title: "Indoor Games Room",
		type: "Artist's Impression",
	},
	{
		src: Lamen2,
		title: "Gymnasium",
		type: "Artist's Impression",
	},
	{
		src: Lamen3,
		title: "Mini Theatre",
		type: "Artist's Impression",
	},
	{
		src: Lamen4,
		title: "Landscaped Area",
		type: "Artist's Impression",
	},
	{
		src: Lamen5,
		title: "Children's Play Area",
		type: "Artist's Impression",
	},
	{
		src: Lamen6,
		title: "Senior Citizen Sitting Area",
		type: "Artist's Impression",
	},
	{
		src: Lamen7,
		title: "Multipurpose Hall",
		type: "Artist's Impression",
	},
	{
		src: Lamen8,
		title: "Jogging Track",
		type: "Artist's Impression",
	},
	{
		src: Lamen9,
		title: "Library",
		type: "Artist's Impression",
	},
];


export const expDivumData = [
	{
		src: DAmen1rep,
		title: "Banquet Hall ",
		type: "Artist's Impression",
	},
	{
		src: DAmen2rep,
		title: "KIDS' PLAY AREA",
		type: "Artist's Impression",
	},
	{
		src: DAmen3rep,
		title: "Fully Equipped Gymnasium",
		type: "Artist's Impression",
	},
	// {
	// 	src: DAmen4rep,
	// 	title: "Kids Play Zone",
	// 	type: "Artist's Impression",
	// },
	{
		src: DAmen5rep,
		title: "Infinity Edge Swimming Pool",
		type: "Artist's Impression",
	},
	{
		src: DAmen6rep,
		title: "Jogging Track",
		type: "Artist's Impression",
	},
	{
		src: DAmen7rep,
		title: "Landscaped Garden",
		type: "Artist's Impression",
	},
	{
		src: DAmen8rep,
		title: "Private Mini Theatre",
		type: "Artist's Impression",
	},
	{
		src: DAmen9rep,
		title: "Library",
		type: "Artist's Impression",
	},
	{
		src: DAmen10rep,
		title: "Yoga Room",
		type: "Artist's Impression",
	},
	// {
	// 	src: DAmen11rep,
	// 	title: "Lobby",
	// 	type: "Artist's Impression",
	// },
	{
		src: DAmen12rep,
		title: "Podium",
		type: "Artist's Impression",
	},
	{
		src: DAmen13rep,
		title: "Seating Area",
		type: "Artist's Impression",
	},
	{
		src: DAmen14rep,
		title: "Meditation Zone",
		type: "Artist's Impression",
	},
];


export const expAvanyaData = [
	{
		src: AAmen1rep,
		title: "Podium",
		type: "'Children's' Play Area",
	},
	{
		src: AAmen2rep,
		title: "Club House",
		type: "Artist's Impression",
	},
	// {
	// 	src: AAmen3rep,
	// 	title: "Children’s Play Area",
	// 	type: "Artist's Impression",
	// },
	{
		src: AAmen4rep,
		title: "Children's Play Area",
		type: "Artist's Impression",
	},
	{
		src: AAmen5rep,
		title: "Gymnasium",
		type: "Artist's Impression",
	},
	{
		src: AAmen6rep,
		title: "Jogging Track",
		type: "Artist's Impression",
	},
	{
		src: AAmen7rep,
		title: "Mini Theatre",
		type: "Artist's Impression",
	},
	{
		src: AAmen8rep,
		title: "Multipurpose Court",
		type: "Artist's Impression",
	},
	{
		src: AAmen9rep,
		title: "Reflexology Lawn",
		type: "Artist's Impression",
	},
	{
		src: AAmen10rep,
		title: "Seating Plaza",
		type: "Artist's Impression",
	},
	{
		src: AAmen11rep,
		title: "Steam & Sauna",
		type: "Artist's Impression",
	},
	{
		src: AAmen12rep,
		title: "Supertree",
		type: "Artist's Impression",
	},
	{
		src: AAmen13rep,
		title: "Swimming Pool",
		type: "Artist's Impression",
	},
	{
		src: AAmen14rep,
		title: "Yoga & Meditation Pavilion",
		type: "Artist's Impression",
	},
	{
		src: AAmen15rep,
		title: "Creche",
		type: "Artist's Impression",
	},
	{
		src: AAmen16rep,
		title: "Games-Room",
		type: "Artist's Impression",
	},
	{
		src: AAmen17rep,
		title: "Kids' Pool",
		type: "Artist's Impression",
	},
	{
		src: AAmen18rep,
		title: "Pet-Park",
		type: "Artist's Impression",
	},

];


export const expParkwoodData = [
	{
		src: Pamen1rep,
		title: "Banquet",
		type: "Children Play area",
	},
	{
		src: Pamen2rep,
		title: "Soft Jogging Track",
		type: "artist's Impression",
	},
	{
		src: Pamen3rep,
		title: "Kids' Play area",
		type: "artist's Impression",
	},
	{
		src: Pamen4rep,
		title: "Landscaped Garden",
		type: "artist's Impression",
	},
	{
		src: Pamen5rep,
		title: "Multipurpose Courts",
		type: "artist's Impression",
	},
	{
		src: Pamen6rep,
		title: "Party Lawn",
		type: "artist's Impression",
	},
	{
		src: Pamen7rep,
		title: "Rock Climbing Wall",
		type: "artist's Impression",
	},
	{
		src: Pamen8rep,
		title: "Swimming Pool",
		type: "artist's Impression",
	},
	{
		src: Pamen9rep,
		title: "Yoga & Wellness Center",
		type: "artist's Impression",
	},
	{
		src: Pamen10rep,
		title: "Business Lounge",
		type: "artist's Impression",
	},
	{
		src: Pamen11rep,
		title: "Cricket Net",
		type: "artist's Impression",
	},
	{
		src: Pamen12rep,
		title: "Gymnasium",
		type: "artist's Impression",
	},
	{
		src: Pamen13rep,
		title: "Indoor Games",
		type: "artist's Impression",
	},

];
export const expdairyslide = [
	{
		src: dairy1,

	},
	{
		src: dairy2,

	},
	{
		src: dairy3,

	},
	{
		src: dairy4,

	},
];
export const exphealthslide = [
	{
		src: healths1,

	},
	{
		src: healths2,

	},
	{
		src: healths3,

	},

];

export const expeducationslider = [
	{
		src: edus1,

	},
	{
		src: edus2,

	},
	{
		src: edus3,

	},
	{
		src: edus4,

	},
	{
		src: edus5,

	},
	{
		src: edus6,

	},
	{
		src: edus7,

	},
	{
		src: edus8,

	},
	{
		src: edus9,

	},
	{
		src: edus10,

	},
	{
		src: edus11,

	},
	{
		src: edus12,

	},
];


export const expAstrumData = [
	{
		src: Asamen1rep,
		title: "Gymnasium",
		type: "artist's Impression",
	},
	{
		src: Asamen2rep,
		title: "Hot Tub",
		type: "artist's Impression",
	},
	{
		src: Asamen3rep,
		title: "Games Room",
		type: "artist's Impression",
	},
	{
		src: Asamen4rep,
		title: "Library",
		type: "artist's Impression",
	},
	{
		src: Asamen5rep,
		title: "Sauna",
		type: "artist's Impression",
	},
	{
		src: Asamen6rep,
		title: "Spa",
		type: "artist's Impression",
	},
	{
		src: Asamen7rep,
		title: "Swimming Pool with Baby Pool",
		type: "artist's Impression",
	},
	{
		src: Asamen8rep,
		title: "Mini Theatre",
		type: "artist's Impression",
	},
	{
		src: Asamen9rep,
		title: "Multipurpose Hall",
		type: "artist's Impression",
	},

];



export const expdegoaData = [
	{
		src: Gamen1rep,
		title: "Grand Club House",
		type: "Children's Play area",
	},
	{
		src: Gamen2rep,
		title: "Swimming Pool",
		type: "artist's Impression",
	},
	{
		src: Gamen3rep,
		title: "Jogging Track",
		type: "artist's Impression",
	},

	// {
	// 	src: Gamen5rep,
	// 	title: "Club House",
	// 	type: "artist's Impression",
	// },
	{
		src: Gamen6rep,
		title: "Banquet",
		type: "artist's Impression",
	},
	{
		src: Gamen7rep,
		title: "Bar",
		type: "artist's Impression",
	},
	{
		src: Gamen8rep,
		title: "Gymnasium",
		type: "artist's Impression",
	},
	{
		src: Gamen9rep,
		title: "Reception",
		type: "artist's Impression",
	},


];
export const raetData = [
	{
		src: raet1rep,
		title: "Landscaped Garden and Amphitheater",
		type: "Children's Play area",
	},
	{
		src: raet2rep,
		title: "Gymnasium",
		type: "artist's Impression",
	},
	{
		src: raet3rep,
		title: "Infinity-edged swimmimg pool",
		type: "artist's Impression",
	},
	{
		src: kidsplayarea,
		title: "kids play area",
		type: "artist's Impression",
	},

];

export const amenitiesfeatureData = [
	{
		src: amenities1rep,
		title: "Gymnasium",
		type: "Children's Play area",
	},
	{
		src: amenities2rep,
		title: "Landscaped Garden",
		type: "artist's Impression",
	},
	// {
	// 	src: amenities3rep,
	// 	title: "swimmimg pool",
	// 	type: "artist's Impression",
	// },
	{
		src: amenities4rep,
		title: "swimmimg pool",
		type: "artist's Impression",
	},
	{
		src: amenities5rep,
		title: "water feature",
		type: "artist's Impression",
	},

];



export const expsrishtiData = [
	{
		src: Samen1rep,
		title: "Club House",
		type: "Children's Play area",
	},
	{
		src: Samen2rep,
		title: "Kids' Play Area",
		type: "artist's Impression",
	},
	{
		src: Samen3rep,
		title: "Landscaped Garden",
		type: "artist's Impression",
	},
	{
		src: Samen4rep,
		title: "SWIMMING POOL",
		type: "artist's Impression",
	},
	{
		src: Samen5rep,
		title: "Podium",
		type: "artist's Impression",
	},
	{
		src: Samen6rep,
		title: "Retail",
		type: "artist's Impression",
	},

];