import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cplakshachandiapartments/hero/Hero';
import Landmark from '../../components/common/cplakshachandiapartments/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Residential Apartments In Goregaon (E) | Lakshachandi Apartments - Dynamix Group</title>
        <meta name="description" content="Lakshachandi Apartments stand out as the ideal residential spaces in Goregaon (East) with multi-facility amenities. Visit to know more!" />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
