import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cppachsheelgarden/hero/Hero';
import Landmark from '../../components/common/cppachsheelgarden/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Residential Apartments In Kandivali (W) | Panchsheel Garden - Dynamix Group</title>
        <meta name="description" content="Located in Kandivali (W), Panchsheel Garden is blessed with peace and calm, accompanied by wide roads, tree-lined avenues, amenities, playground, schools and hospitals." />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
