import React from 'react'
import './completedproject.scss';

const CompletedProject = () => {
  return (
    <div className="listing-page">
      <div className="text-center">
        <div className="main-heading">
          <div className="psudo-box">
            <h6>Our projects</h6>
            <span className="psudo-border"></span>
          </div>
          <h1 className='new-htwo-hone margin-none'>Completed Projects</h1>
        </div>
        <div className="para-detail mt-4">
          <p>Building Tomorrow's Communities Today: Explore Our Ongoing Projects</p>
        </div>
      </div>
    </div>
  )
}

export default CompletedProject
  