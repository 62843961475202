import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { textAnimate } from '../projects/Projects';
import { useInView } from 'react-intersection-observer';
// Components
import LayoutMain from '../../../layout/LayoutMain/LayoutMain';
// Stylesheet
import './counting.scss';
import { Link } from 'react-router-dom';

const Counting = () => {
  const [animate, setAnimate] = useState(false);
  const { ref, inView } = useInView();
  useEffect(() => {
    if (inView) {
      setAnimate(true);
    } else setAnimate(false);
  }, [inView]);

  useEffect(() => {
    const workSection = document.querySelector('.counting');
    const rightContainer = document.querySelectorAll('.counting .right_container>div');
    const workObserver = new IntersectionObserver(
      (entries, observer) => {
        const [entry] = entries;

        if (!entry.isIntersecting) return;

        const counterNum = document.querySelectorAll('.counter-number');
        const speed = 10;

        rightContainer.forEach((elem) => {
          elem.style.transform = 'skewY(0deg)';
          elem.style.transition = 'transform 1s';
        });
        counterNum.forEach((curElem) => {
          const updateNumber = () => {
            const targetNumber = parseInt(curElem.dataset.number);
            const initialNum = parseInt(curElem.innerText);
            const incrementNumber = Math.trunc(targetNumber / speed);

            if (initialNum < targetNumber) {
              curElem.innerText = `${initialNum + incrementNumber}+`;
            }

            setTimeout(updateNumber, 54);
          };
          updateNumber();
        });
      },

      {
        root: null,
        threshold: 0.9
      }
    );
    workObserver.observe(workSection);
  });

  return (
    <LayoutMain>
      <section className="counting">
        <motion.div className="left_container" initial="offscreen" whileInView="onscreen">
          <motion.p variants={textAnimate}>The creators of the finest and largest residential townships across</motion.p>
          <motion.h2 className="title_underline" variants={textAnimate}>
            Mumbai, Thane, and Goa
          </motion.h2>
          <div>
            <Link to={'/our-legacy'} onClick={() => window.scrollTo(0, 0)}>
              <button className="btn_primary">
                <span>Our Legacy</span>
              </button>
            </Link>
          </div>
        </motion.div>

        <div className="right_container">
          <div>
            <h1 className="counter-number" data-number="50">
            4-
            </h1>
            <p>
              Year <br /> Legacy
            </p>
          </div>
          <div>
            <h1 className="counter-number" data-number="32">
              -1
            </h1>
            <p>
              Million SQ. Ft. Developed
            </p>
          </div>
          <div>
            <h1 className="counter-number" data-number="30000">
              0+
            </h1>
            <p>
              Homes <br /> Delivered
            </p>
          </div>
        </div>
      </section>
    </LayoutMain>
  );
};

export default Counting;
