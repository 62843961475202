import React, { useRef, useEffect } from 'react';
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import LG1 from "../../../../assets/gallery/LG1.webp";
import LG2 from "../../../../assets/gallery/LG2.webp";
import LG3 from "../../../../assets/gallery/LG3.webp";
import LG4 from "../../../../assets/gallery/LG4.webp";
import LG5 from "../../../../assets/gallery/LG5.webp";
import LG6 from "../../../../assets/gallery/LG6.webp";
import LG7 from "../../../../assets/gallery/LG7.webp";
import LG8 from "../../../../assets/gallery/LG8.webp";
// import LG9 from "../../../../assets/gallery/LG9.webp";
// import LG10 from "../../../../assets/gallery/LG10.webp";
// import LG11 from "../../../../assets/gallery/LG11.webp";
// import LG12 from "../../../../assets/gallery/LG12.webp";
// import LG13 from "../../../../assets/gallery/LG13.webp";
// Stylesheet
import "./galleryluma.scss";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}



const Collage = () => {
  return (
    <Fancybox
      options={{
        Carousel: {
          infinite: false,
        },
      }}
    >
      <section className="gallerycollapseluma">
        <>
          <div className="gallary_containerluma">
            <div className="gallary_item grid_row_span_2">
              <a data-fancybox="gallery" href={LG1}>
                <img src={LG1} alt="gallery img" />
                <div class="overlay">
                  <span className="hover-text">ELEVATION</span>
                </div>
              </a>
            </div>
            <div className="gallary_item ">
              <a data-fancybox="gallery" href={LG2}>
                <img src={LG2} alt="gallery img" />
                <div class="overlay">
                  <span className="hover-text">LOW ANGLE ELEVATON</span>
                </div>
              </a>
            </div>
            <div className="gallary_item grid_col_span_2 grid_row_span_2">
              <a data-fancybox="gallery" href={LG3}>
                <img src={LG3} alt="gallery img" />
                <div class="overlay">
                  <span className="hover-text">SIDE VIEW ELEVATION</span>
                </div>
              </a>
            </div>
            <div className="gallary_item">
              <a data-fancybox="gallery" href={LG4}>
                <img src={LG4} alt="gallery img" />
                <div class="overlay">
                  <span className="hover-text">BATHROOM</span>
                </div>
              </a>
            </div>
          </div>
          <div className="gallary_containerluma">
            <div className="gallary_item grid_row_span_2">
              <a data-fancybox="gallery" href={LG5}>
                <img src={LG5} alt="gallery img" />
                <div class="overlay">
                  <span className="hover-text">MASTER BEDROOM</span>
                </div>
              </a>
            </div>
            <div className="gallary_item ">
              <a data-fancybox="gallery" href={LG6}>
                <img src={LG6} alt="gallery img" />
                <div class="overlay">
                  <span className="hover-text">LIVING ROOM</span>
                </div>
              </a>
            </div>
            <div className="gallary_item grid_col_span_2 grid_row_span_2">
              <a data-fancybox="gallery" href={LG7}>
                <img src={LG7} alt="gallery img" />
                <div class="overlay">
                  <span className="hover-text">KITCHEN</span>
                </div>
              </a>
            </div>
            <div className="gallary_item">
              <a data-fancybox="gallery" href={LG8}>
                <img src={LG8} alt="gallery img" />
                <div class="overlay">
                  <span className="hover-text">DINNING AREA</span>
                </div>
              </a>
            </div>
          </div>
        </>
      </section>
    </Fancybox>
  )
}

const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <div className="gallary_title">
          <p>GALLERY</p>
          <h2>EXPLORE LUMA</h2>
        </div>
      </LayoutMain>
      <Layout>
        {/* Single collage */}
        <Collage />
        {/* Single collage */}
      </Layout>
    </section>

  );
};


export default Gallery;
