import React from 'react'
// Components
import ExpSlider from './ExpSlider'
// Stylesheet
import './experienceavanya.scss'

// Data
import { expAvanyaData } from '../../../../constants/expData'

const Experience = () => {
    return (
        <section className='expavanya' id='Amenities'>
            <div className='exp_title_container'>
                <div>
                <p>AMENITIES</p>
                <h2>Indulge in a Diverse Range of<br/>Lifestyle Amenities</h2>
                </div>
            </div>
            <ExpSlider expData={expAvanyaData} />
        </section>
    )
}

export default Experience
