import React from 'react';
import './heroastrum.scss';
import { motion } from 'framer-motion';


const textAnimate = {
    offscreen: { opacity: 0, y: 300 },
    onscreen: { opacity: 1, y: 0, transition: { duration: 1, delay: 0.25 } }
};

const Hero = () => {
    return (
        <motion.section className='pdp_heroastrumastrum overlay-bg'>
            <motion.div className="hero_content" initial="offscreen" whileInView="onscreen" variants={textAnimate}>
                <h1 className='new-hone' >Astrum <span className='new-hone-hthree'>Malad East</span> <span className='titlepara'>Super premium 2 & 3 bed residences at Malad East</span></h1>
                {/* <h3 className="detail">Malad East</h3>
                <p>
                Super premium 2 & 3 bed residences at Malad East
                </p> */}
            </motion.div>
        </motion.section>

    )
}

export default Hero
