import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/aldeiadegoa/hero/Hero';
import BottomNav from '../../components/common/aldeiadegoa/bottomNav/BottomNav';
import Landmark from '../../components/common/aldeiadegoa/landmark/Landmark';
import Info from '../../components/common/aldeiadegoa/info/Info';
import Experience from '../../components/common/aldeiadegoa/experience/Experience';
import Features from '../../components/common/aldeiadegoa/features/Features';
import Gallery from '../../components/common/aldeiadegoa/gallery/Gallery';
import Walkthrough from '../../components/common/aldeiadegoa/walkthrough/Walkthrough';
// import VirtualTour from '../../components/common/aldeiadegoa/virtualTour/VirtualTour';
// import Exterior from '../../components/common/aldeiadegoa/exterior/Exterior';
import FloorPlan from '../../components/common/aldeiadegoa/floorPlan/FloorPlan';
import Barcode from '../../components/common/aldeiadegoa/barcode/Barcode';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { pdpBottomNavData } from '../../constants/pdpBottomNavData';
import { Helmet } from "react-helmet";





const AldeiadeGoaPage = () => {
  return (
    <>
      <Helmet>
        <title>Seaside Apartments In Goa, India | Aldeia de Goa - Dynamix Group</title>
        <meta name="description" content="Discover the best apaartments with sea views at finest seaside townships in Goa. We offers 2 & 3 BHK apartments and villas. Contact at 022 4896 5555" />
      </Helmet>
      <Navigation />
      <Hero />
      <BottomNav pdpBottomNavData={pdpBottomNavData} />
      <Landmark />
      <Info />
      <Experience />
      <Features />
      <Gallery />
      <Walkthrough />
      {/* <VirtualTour /> */}
      {/* <Exterior /> */}
      <FloorPlan />
      <Barcode />
      <Contact />
      <Footer />
    </>
  );
};

export default AldeiadeGoaPage;
