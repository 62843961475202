import React from 'react';
import Navigation from '../../components/common/navigation/Navigation';
import TopmainHeading from '../../components/common/aboutpage/topmainheading/TopmainHeading';
import FullBanner from '../../components/common/aboutpage/fullbanner/FullBanner';
import ValuePage from '../../components/common/aboutpage/valuepage/ValuePage';
import Counting from '../../components/common/home/counting/Counting';
import EthosPage from '../../components/common/aboutpage/ethospage/EthosPage';
import GroupPage from '../../components/common/aboutpage/grouppage/GroupPage';
import Principle from '../../components/common/aboutpage/principle/Principle';
import Management from '../../components/common/aboutpage/management/Management';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import BottomNav from '../../components/common/pdp/bottomNav/BottomNav';
import { aboutBottomNavData } from '../../constants/aboutBottomNavData';
import { useLocation } from 'react-router-dom';
import {Helmet} from "react-helmet";


const AboutPage = () => {

  const location = useLocation();
  if (location.hash) {
    setTimeout(() => {
      var anchor = document.querySelector(location.hash).getBoundingClientRect();
      window.scrollTo(anchor.left, anchor.top + window.scrollY - 60);
    }, 1000);

  }

  return (
    <>

      <Helmet>
        <title>About Dynamix Group | Top Real Estate Developer in Mumbai</title>
        <meta name="description" content="We are a leading real estate developer based in Mumbai. We have been serving as one of the top real estate developers for 5 decades. Click to know more!" />
      </Helmet>
      <Navigation />
      <TopmainHeading />
      <BottomNav pdpBottomNavData={aboutBottomNavData} />
      <FullBanner />
      <ValuePage />
      <Counting />
      <EthosPage />
      <GroupPage />
      <Principle />
      <Management />
      <Contact />
      <Footer />
    </>
  );
};

export default AboutPage;
