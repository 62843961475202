import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpjasminetower/hero/Hero';
import Landmark from '../../components/common/cpjasminetower/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";




const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>2 BHK Residential Apartments In Thane | Jasmine Tower - Dynamix Group</title>
        <meta name="description" content="2 BHK residential apartments at Jasmine Towers evoke an image of bliss and peaceful living with open spaces, well-connected roads, and reinvigorating nature." />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
