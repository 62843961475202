import React from 'react'
// Components
import ExpSlider from './ExpSliderdivum'
// Stylesheet
import './experiencedivum.scss'

// Data
import { expDivumData } from '../../../../constants/expData'

const Experience = () => {
    return (
        <section className='expdivum' id='Amenities'>
            <div className='exp_title_container'>
                <div>
                <p>AMENITIES</p>
                <h2>AMENITIES THAT COME WRAPPED AROUND AN AIR OF ELEGANCE</h2>
                </div>
            </div>
            <ExpSlider expData={expDivumData} />
        </section>
    )
}

export default Experience
