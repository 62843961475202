import React from 'react';
import './features.scss';
import { keyfeaturesdata } from '../../../../constants/keyfeaturesdata';

const Features = () => {
    return (
        <section className='divumpdp_features' id='Project'>
            <div className='features_title_container'>
                <p>PROJECT FEATURES</p>
                <h2>Experience a New Standard of Living</h2>
            </div>
            <div className='features_items'>
                {
                    keyfeaturesdata.map((item, key) => (
                        <div className='features_item' key={key}>
                            {/* <div className='bullet'></div> */}
                            <div classname='bullet-icon'>
                            <img src={item.src} alt="slider img" />
                            </div>
                            <p>{item.title}</p>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default Features
