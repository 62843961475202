import React from 'react';
import Layout from '../../../layout/Layout/Layout';

import './virtualTourluma.scss'

const VirtualTour = () => {//
  return (
    <Layout>
    <section className='virtual_tourluma' id='Virtual'>
      <div className='title_container'>
        <p>VIRTUAL TOUR</p>
        <h2>EXPLORE AN APARTMENT <br />AT LUMA</h2>
        
      </div>
      <iframe key="virtual-tour-1" width="100%" height="500" src="https://www.dynamixgroup.co.in/virtual-tour-luma/luma/" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>

    </section>
    </Layout>
  )
}

export default VirtualTour


