//current
import GOA from '../assets/project/GOA.webp'
import Astrum from '../assets/project/Astrum.webp'
import Avanya from '../assets/project/Avanya.webp'
import Divum from '../assets/project/Divum.webp'
import Luma from '../assets/project/Luma.webp'
import Parkwood from '../assets/project/Parkwood.webp'
import Srishti from '../assets/project/Srishti.webp'
//completed
import woodlandsbanner from '../assets/project/woodlandsbanner.webp'
import parkresidencebanner from '../assets/project/parkresidencebanner.webp'
import boulevardbanner from '../assets/project/boulevardbanner.webp'
import lakshachandiheightsbanner from '../assets/project/lakshachandiheightsbanner.webp'
import lakshchandiaptsbanner from '../assets/project/lakshchandiaptsbanner.webp'
import jasminetowerbanner from '../assets/project/jasminetowerbanner.webp'
import shagunbanner from '../assets/project/shagunbanner.webp'
import sahyadribanner from '../assets/project/sahyadribanner.webp'
import prakritibanner from '../assets/project/prakritibanner.webp'
import panchsheelheightsbanner from '../assets/project/panchsheelheightsbanner.webp'
import panchsheelgardenbanner from '../assets/project/panchsheelgardenbanner.webp'
import ascotcentrebanner from '../assets/project/ascotcentrebanner.webp'
import upvantowerbanner from '../assets/project/upvantowerbanner.webp'
import udyogbhavanbanner from '../assets/project/udyogbhavanbanner.webp'
import swapnalokbanner from '../assets/project/swapnalokbanner.webp'
import shikharkunjbanner from '../assets/project/shikharkunjbanner.webp'









export const projectData1 = [
    {
        link: "/current-projects/luma",
        place: "Andheri East",
        title: "Luma",
        src: Luma
    },
    {
        link: "/current-projects/parkwoods",
        place: "Thane West",
        title: "Parkwoods",
        src: Parkwood
    },
    {
        link: "/current-projects/divum",
        place: "Goregaon Precinct",
        title: "Divum",
        src: Divum
    },
    {
        link: "/current-projects/avanya",
        place: "Dahisar East",
        title: "Avanya",
        src: Avanya
    },
    {
        link: "/current-projects/astrum",
        place: "Malad East",
        title: "Astrum",
        src: Astrum
    },
    {
        link: "/current-projects/aldeiadegoa",
        place: "Bambolim, Goa",
        title: "Aldeia De Goa",
        src: GOA
    },
    {
        link: "/current-projects/srishtinamaah",
        place: "Mira Road",
        title: "Srishti namaah",
        src: Srishti
    },

]
export const projectData2 = [
    {
        link: "/completed-projects/woodlands",
        place: "Malad East",
        title: "Woodlands",
        src: woodlandsbanner
    },
    {
        link: "/completed-projects/lakshachandi-heights",
        place: "Goregaon East",
        title: "Lakshachandi Heights",
        src: lakshachandiheightsbanner
    },
    {
        link: "/completed-projects/lakshachandi-apartments",
        place: "Goregaon East",
        title: "Lakshachandi Apartments",
        src: lakshchandiaptsbanner
    },
    {
        link: "/completed-projects/jasmine-tower",
        place: "Vasant vihar, Thane",
        title: "Jasmine Tower",
        src: jasminetowerbanner
    },
    {
        link: "/completed-projects/shagun-mall-tower",
        place: "Malad East",
        title: "Shagun Mall Tower",
        src: shagunbanner
    },
    {
        link: "/completed-projects/sahyadri",
        place: "Malad East",
        title: "Sahyadri",
        src: sahyadribanner
    },
    {
        link: "/completed-projects/prakriti",
        place: "Goregaon East",
        title: "Prakriti",
        src: prakritibanner
    },
    {
        link: "/completed-projects/pachsheel-heights",
        place: "Kandiwali West",
        title: "Panchsheel Heights",
        src: panchsheelheightsbanner
    },
    {
        
        link: "/completed-projects/pachsheel-garden",
        title: "Panchsheel Garden",
        src: panchsheelgardenbanner
    },
    
    {
        link: "/completed-projects/ascot-centre",
        place: "Andheri East",
        title: "Ascot Centre",
        src: ascotcentrebanner
    },
    {
        link: "/completed-projects/upvan-tower",
        place: "Malad East",
        title: "Upvan Tower",
        src: upvantowerbanner
    },
    {
        link: "/completed-projects/udyog-bhawan",
        place: "Goregaon West",
        title: "Udyog Bhawan",
        src: udyogbhavanbanner
    },
    {
         link: "/completed-projects/swapnalok",
        place: "Malad East",
        title: "Swapnalok",
        src: swapnalokbanner
    },
    {
        link: "/completed-projects/shikharkunj",
        place: "Malad East",
        title: "Shikharkunj",
        src: shikharkunjbanner
    },
    
    {
         link: "/completed-projects/the-park-residence",
        place: "Malad East",
        title: "Park Residence",
        src: parkresidencebanner
    },
    {
         link: "/completed-projects/boulevard-15",
        place: "Malad East",
        title: "Boulevard-15",
        src: boulevardbanner
    },
]
