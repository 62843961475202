import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Hero from '../../components/common/cpswapnalok/hero/Hero';
import Landmark from '../../components/common/cpswapnalok/landmark/Landmark';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const DetailCompletedProjects = () => {
  return (
    <>
      <Helmet>
        <title>Residential Project In Malad | Swapnalok - Dynamix Group</title>
        <meta name="description" content="Swapnalok’s best part is that everything – right from schools, colleges and hospitals to markets, playgrounds, malls and temples is nearby." />
      </Helmet>
      <Navigation />
      <Hero />
      <Landmark />
      <Contact />
      <Footer />
    </>
  );
};

export default DetailCompletedProjects;
