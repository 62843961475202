import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/Parkwoods-Overview.webp";
// Stylesheet
import "./landmarkparkwood.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkparkwood' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>Your Sanctuary</h2>
                    <img src={LandmarkImg} alt="landmark img" />
                </div>
                <div>
                    <h4>Located off Ghodbunder Road, Parkwoods promises excellent connectivity, including the proposed metro
                        station a mere 100 metres away. It is surrounded by open greens, as well as planned social infrastructure, including DMart and a proposed school to be built on the larger layout of the property among numerous additions enhance your comfort and convenience further. Our spacious homes take you and your family’s lifestyle to the next level. Surrounded by plush amenities and spacious interiors, ushering more glamour into your life.</h4>
                    {/* <h4>Your home is a sanctuary—a place that offers comfort, peace of mind, familiarity, and security. It is a deeply personal space that belongs solely to you, where you can find solace amidst the bustle of your fast-paced life. These emotions serve as the inspiration behind Parkwoods.</h4>
                    <p>Parkwoods, a premium residential project, is dedicated to fulfilling your every desire for community, comfort, entertainment, and tranquillity. Nestled along Ghodbunder Road in Thane West, this development offers spacious 2 BHK apartments that are thoughtfully designed with elegant interiors and equipped with air-conditioning, elevating your everyday living experience to sheer bliss.</p> */}
                    {/* <h4>Welcome to the grand finale of Parkwoods. It’s the homestretch for Thane's most prestigious address. Own a home in one of the city’s tallest and most luxurious new outings. Perched high above the cityscape, this final tower promises unparalleled views and an unmatched lifestyle. As the curtains close on this remarkable project, don't miss this exclusive opportunity to own a piece of Thane's skyline.</h4> */}
                    {/* <p>Parkwoods, a premium residential project, is dedicated to fulfilling your every desire for community, comfort, entertainment, and tranquillity. Nestled along Ghodbunder Road in Thane West, this development offers spacious 2 BHK apartments that are thoughtfully designed with elegant interiors and equipped with air-conditioning, elevating your everyday living experience to sheer bliss.</p> */}
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
